import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [paqueteria, setPaqueteria] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [numero_pedido, setNumeroPedido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);
  const [iva, setIva] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [surtido, setSurtido] = useState("");
  const [nombreClienteEdit, setNombreClienteEdit] = useState("");
  const [idClienteEdit, setIdClienteEdit] = useState("");
  const [fechaModificado, setFechaModificado] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);
  const [descuento, setDescuento] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalNoDisponible, setModalNoDisponible] = useState(false);
  const toggleNoDisponible = () => setModalNoDisponible(!modalNoDisponible);
  const [allArticulosAutorizar, setAllArticulosAutorizar] = useState([]);

  const [inventarios, setInventarios] = useState([]);

  const [status, setStatus] = useState("Abierto");
  const [autorizadoApartado, setAutorizadoApartado] = useState("");
  const [autorizadoSurtido, setAutorizadoSurtido] = useState("");

  const [cambiarPrecio, setCambiarPrecio] = useState(false);
  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [AbrirEditar, setAbrirEditar] = useState(false);

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_PEDIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;

        // Filtrar pedidos si user es vendedor
        if (user.vendedor === "Si") {
          allPedidos = allPedidos.filter(
            (pedido) => pedido.colaboradores[0].email === user.email
          );
        }
        // console.log("hola", allPedidos)
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedido,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              status: a.status,
              autorizadoApartado: a.autorizadoApartado,
              autorizadoSurtido: a.autorizadoSurtido,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              descuento: a.descuento,
              paqueteria: a.paqueteria,
              surtido: a.surtido,
              codigoClientes: a.clientes[0].codigo,
              calle: a.clientes[0].calle,
              numero_ext: a.clientes[0].numero_ext,
              numero_int: a.clientes[0].numero_int,
              pais: a.clientes[0].pais,
              estado: a.clientes[0].estado,
              ciudad: a.clientes[0].ciudad,
              delegacion: a.clientes[0].delegacion,
              colonia: a.clientes[0].colonia,
              cp: a.clientes[0].cp,
              RFC: a.clientes[0].RFC,
              fechaModificado: a.fechaModificado,
              telefono: a.clientes[0].telefono,
              editado: a.editado,
              editPedido: a.editPedido,
              autorizoApartado: a.autorizoApartado,
              autorizoSurtido: a.autorizoSurtido,
              fechaInicio: a.fechaInicio,
              fechaCancelacion: a.fechaCancelacion,
              piezasEntregadas: a.piezasEntregadas,
              piezasPendientes: a.total_cantidad - a.piezasEntregadas,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}GeneralActivos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}Disponible`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setInventarios(allDisponible);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin, status]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      headStyles: {
        fillColor: ["#f28029"],
        lineWidth: 0,
      },
      footStyles: {
        fillColor: ["#f28029"],
      },
      theme: "grid",
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      headStyles: {
        fillColor: ["#f28029"],
        lineWidth: 0,
      },
      footStyles: {
        fillColor: ["#f28029"],
      },
      theme: "grid",
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    idColaboradores,
    idClientes,
    total_cantidad,
    total_general,
    observaciones,
    numero_pedido,
    comisionTDC,
    comisionFlete,
    impuestos,
    paqueteria,
    idEdit
  ) {
    setFecha(fecha);
    setSelectedColaboradorEdit(idColaboradores);
    setSelectedClienteEdit(idClientes);
    setTotalCantidad(total_cantidad);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroPedido(numero_pedido);
    setComisionTDC(comisionTDC);
    setComisionFlete(comisionFlete);
    setSelectedImpuesto(impuestos);

    setPaqueteria(paqueteria);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    impuestos,
    descuento,
    comisionFlete,
    comisionTDC,
    idPDFOC,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    codigoClientes,
    autorizadoApartado,
    autorizadoSurtido,
    telefono,
    editado,
    editPedido,
    autorizoApartado,
    autorizoSurtido,
    subtotal,
    iva
  ) {
    console.log(subtotal, iva, descuento)
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              idArticulo: a.articulos[0]._id,
              fotoArticulo: a.articulos[0].foto,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              autorizoApartado: a.pedidos[0].autorizoApartado,
              autorizoSurtido: a.pedidos[0].autorizoSurtido,
              foto: "",
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let iva_tabla = (TG * impuestos) / 100;

        dataFinal.map(async (z) => {
          if (z.fotoArticulo == "Si") {
            const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${z.idArticulo}`;
            await axios
              .get(URL_GET_MEDIA, {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              })
              .then((data) => {
                if (data.data.secure_url != null) {
                  z.foto = data.data.secure_url;
                } else z.foto = process.env.PUBLIC_URL + "sinImagen.png";
              })
              .catch((err) => {
                console.log(err);
              });
            return z;
          } else {
            z.foto = process.env.PUBLIC_URL + "sinImagen.png";
          }
        });

        setTimeout(() => {
          const data = dataFinal.map((a) => {
            // totalParcial = a.cantidad * a.precio;
            // subTotalTabla = subTotalTabla + totalParcial
            // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
            return [
              a.foto,
              a.articulos,
              new Intl.NumberFormat("en-US").format(a.cantidad) +
                " Pzas / " +
                new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(a.cajas) +
                " Cajas",
              new Intl.NumberFormat("en-US").format(a.surtido),
              new Intl.NumberFormat("en-US").format(a.pendiente_surtir),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.precio),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.total),
            ];
          });

          const dataPDF = Object.values(data);
          const dataPDFLimpia = dataPDF.filter(function (el) {
            return el != null;
          });

          const columnStyles = [
            { fontSize: 0.000001, textColor: [255, 255, 255], cellWidth: 20 },
            { fontSize: 9, textColor: ["#000000"] },
            { fontSize: 9, textColor: ["#000000"] },
            { fontSize: 9, textColor: ["#000000"], halign: "center" },
            { fontSize: 9, textColor: ["#000000"], halign: "center" },
            { fontSize: 9, textColor: ["#000000"], halign: "right" },
            { fontSize: 9, textColor: ["#000000"], halign: "right" },
          ];

          const doc = new jsPDF({ compressPdf: true });
          var img = new Image();

          var img2 = new Image();
          const backgroundColor = ["#d1d2d4"];
          const backgroundColorBoxLightGray = ["#e6e7e9"];
          const backgroundColorBoxBlack = ["#231f20"];
          const backgroundColorBoxPurple = ["#f28029"];
          const margins = { left: 5, right: 5 };

          img.src = process.env.PUBLIC_URL + "logoInstitucion.png";
          doc.addImage(img, "png", 15, 5, 33, 33);

          // img.src = process.env.PUBLIC_URL + "marcas.png";
          // doc.addImage(img, "png", 9, 28, 192, 10);
          // doc.setFontSize(10);

          doc.setFillColor(...backgroundColor);
          doc.rect(5, 40, 200, 6, "F");
          doc.setTextColor("#000000");
          doc.setFontSize(10);
          doc.text(`Tel: 55 1234 5678`, 8, 44);

          doc.setTextColor("#000000");

          doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 8, 52);
          doc.text(
            `Direccion: ${calle} ${numero_ext} ${numero_int} ${estado} ${ciudad} ${cp}`,
            8,
            57
          );
          doc.text(`Vendedor: ${colaboradores}`, 8, 62);
          doc.text(`Pedido: ${numero}`, 180, 52);

          // doc.setTextColor("#f28029");
          // doc.setFontSize(12);
          // doc.setFont("helvetica", "bold");
          // doc.text(`americanFly.mx`, 175, 44);
          // doc.setTextColor("#000000");

          doc.setFontSize(14);
          doc.setFillColor(...backgroundColorBoxBlack);
          doc.rect(100, 4, 105, 22, "F");
          doc.setFillColor(...backgroundColorBoxPurple);
          doc.rect(74, 4, 25, 22, "F");
          doc.setTextColor("#ffffff");
          doc.text(`PEDIDO`, 125, 12);
          doc.setFont("helvetica", "normal");
          doc.text(`México, D. F. a ${fecha}`, 125, 22);

          doc.autoTable({
            head: [
              [
                "",
                "PRODUCTO",
                "CANTIDAD",
                "CANT. SURT.",
                // "CANT. SURTIDA",
                // "CANT. POR SURTIR",
                "CANT. X SURT.",
                // "PRECIO UNIT.",
                "PRECIO",
                "IMPORTE",
              ],
            ],
            body: dataPDFLimpia,
            bodyStyles: {
              rowHeight: 22,
              valign: "middle",
            },
            styles: {
              fontSize: 10,
              // overflow: "linebreak",
              // columnWidth: "wrap",
              // // font: 'arial',
              // overflowColumns: "linebreak",
            },
            headStyles: {
              fillColor: ["#f28029"],
              lineWidth: 0,
              halign: "center",
              valign: "middle",
              fontSize: 8,
            },
            footStyles: {
              fillColor: ["#f28029"],
            },
            columnStyles: columnStyles,
            theme: "grid",
            margin: { left: 5, right: 5 },
            startY: 70,

            didDrawCell: function (data) {
              if (data.column.index === 0 && data.cell.section === "body") {
                var td = data.cell.raw;
                var img = td;
                //  var dim = data.cell.height - data.cell.padding('vertical');
                var textPos = data.cell;
                doc.addImage(img, textPos.x, textPos.y, 20, 20);
              }
            },

            showFoot: "lastPage",
          });

          doc.setFillColor(...backgroundColorBoxPurple);
          doc.rect(5, doc.autoTable.previous.finalY + 1, 36, 6, "F");
          doc.setTextColor("#ffffff");
          doc.setFontSize(8);
          doc.text(`TOTAL DE ARTICULOS`, 7, doc.autoTable.previous.finalY + 5);
          doc.setTextColor("#000000");
          // doc.text(
          //   `________________________________________________________________________________________________________________________________`,
          //   5,
          //   doc.autoTable.previous.finalY + 30
          // );

          doc.setFillColor(...backgroundColorBoxLightGray);
          doc.setFont("helvetica", "normal");
          doc.rect(40, doc.autoTable.previous.finalY + 1, 40, 6, "F");
          doc.text(
            new Intl.NumberFormat("en-US").format(TK) + " Piezas",
            42,
            doc.autoTable.previous.finalY + 5
          );

          doc.setFontSize(7.5);
          doc.text(
            `1. Esta es una venta en firme y no se acepta ningún tipo de descuento o devolución sin consentimiento previo.`,
            5,
            doc.autoTable.previous.finalY + 11
          );
          doc.text(
            `2. La mercancía viaja por cuenta y riesgo del cliente, no somos responsables por faltante.`,
            5,
            doc.autoTable.previous.finalY + 14
          );
          doc.text(
            `3. El pago de la mercancía debe realizarse en tiempo y forma pactados a la cuenta proporcionada por nosotros.`,
            5,
            doc.autoTable.previous.finalY + 17
          );

          doc.setFillColor(...backgroundColorBoxPurple);
          doc.rect(144, doc.autoTable.previous.finalY + 1, 37, 6, "F");
          doc.setTextColor("#ffffff");
          doc.setFont("helvetica", "bold");
          doc.setFontSize(8);
          doc.text(`SUBTOTAL $`, 153, doc.autoTable.previous.finalY + 5);

          doc.setFillColor(...backgroundColorBoxLightGray);
          doc.setTextColor("#000000");
          doc.setFont("helvetica", "normal");
          doc.rect(181, doc.autoTable.previous.finalY + 1, 24, 6, "F");
          doc.text(
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(subtotal.toFixed(2)),
            183,
            doc.autoTable.previous.finalY + 5
          );

          doc.setFillColor(...backgroundColorBoxPurple);
          doc.rect(144, doc.autoTable.previous.finalY + 8, 37, 6, "F");
          doc.setTextColor("#ffffff");
          doc.setFont("helvetica", "bold");
          doc.setFontSize(8);
          doc.text(`FLETE $`, 153, doc.autoTable.previous.finalY + 12);

          doc.setFillColor(...backgroundColorBoxLightGray);
          doc.setTextColor("#000000");
          doc.setFont("helvetica", "normal");
          doc.rect(181, doc.autoTable.previous.finalY + 8, 24, 6, "F");
          doc.text(
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(comisionFlete),
            183,
            doc.autoTable.previous.finalY + 12
          );

          doc.setFillColor(...backgroundColorBoxPurple);
          doc.rect(144, doc.autoTable.previous.finalY + 15, 37, 6, "F");
          doc.setTextColor("#ffffff");
          doc.setFont("helvetica", "bold");
          doc.setFontSize(8);
          doc.text(
            `DESCUENTO ${descuento}%`,
            153,
            doc.autoTable.previous.finalY + 19
          );

          doc.setFillColor(...backgroundColorBoxLightGray);
          doc.setTextColor("#000000");
          doc.setFont("helvetica", "normal");
          doc.rect(181, doc.autoTable.previous.finalY + 15, 24, 6, "F");
          doc.text(
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(((subtotal * descuento) / 100).toFixed(2)),
            183,
            doc.autoTable.previous.finalY + 19
          );

          doc.setFillColor(...backgroundColorBoxPurple);
          doc.rect(144, doc.autoTable.previous.finalY + 22, 37, 6, "F");
          doc.setTextColor("#ffffff");
          doc.setFont("helvetica", "bold");
          doc.setFontSize(8);
          doc.text(`IMPUESTO $`, 153, doc.autoTable.previous.finalY + 26);

          doc.setFillColor(...backgroundColorBoxLightGray);
          doc.setTextColor("#000000");
          doc.setFont("helvetica", "normal");
          doc.rect(181, doc.autoTable.previous.finalY + 22, 24, 6, "F");
          doc.text(
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(iva),
            183,
            doc.autoTable.previous.finalY + 26
          );

          

          doc.setFillColor(...backgroundColorBoxPurple);
          doc.rect(144, doc.autoTable.previous.finalY + 29, 37, 6, "F");
          doc.setTextColor("#ffffff");
          doc.setFont("helvetica", "bold");
          doc.setFontSize(8);
          doc.text(`TOTAL $`, 153, doc.autoTable.previous.finalY + 34);

          doc.setFillColor(...backgroundColorBoxLightGray);
          doc.setTextColor("#000000");
          doc.setFont("helvetica", "normal");
          doc.rect(181, doc.autoTable.previous.finalY + 29, 24, 6, "F");
          doc.text(
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(total_general.toFixed(2)),
            183,
            doc.autoTable.previous.finalY + 34
          );

          var lines = doc.splitTextToSize(
            `Observaciones: ${observaciones}`,
            40
          );
          doc.text(145, doc.autoTable.previous.finalY + 40, lines);

          doc.setFillColor(...backgroundColorBoxLightGray);
          doc.rect(5, doc.autoTable.previous.finalY + 47, 200, 40, "F");
          doc.setFont("helvetica", "normal");
          doc.text(
            `Confirmado y autorizado por:`,
            47,
            doc.autoTable.previous.finalY + 57
          );
          doc.text(
            `Para: ${clientes}`,
            125,
            doc.autoTable.previous.finalY + 57
          );
          doc.setFont("helvetica", "bold");
          doc.text(
            `______________________________`,
            35,
            doc.autoTable.previous.finalY + 72
          );
          doc.text(
            `______________________________`,
            120,
            doc.autoTable.previous.finalY + 72
          );
          doc.text(
            `FIRMA(S) AUTORIZADA(S)`,
            42,
            doc.autoTable.previous.finalY + 77
          );
          doc.text(
            `FIRMA(S) AUTORIZADA(S)`,
            125,
            doc.autoTable.previous.finalY + 77
          );

          doc.save(`Pedido-${numero}.pdf`);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function excelOC(id, numero) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              Articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              Cantidad:
                a.cantidad +
                " Pzas. / " +
                a.cantidad / a.articulos[0].piezasPorCaja +
                " Cajas",
              Surtido: a.surtido,
              PendSurtir: a.pendiente_surtir,
              Cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              Precio: a.precio,
              Importe: a.total,
              Surtido: a.surtido,
              Pendiente_Surtir: a.pendiente_surtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const dataExportExcel = Object.values(arrayTabla);
        const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

        var option = {};

        option.fileName = `Pedido ${numero}`;

        option.datas = [
          {
            sheetData: dataExcelLimpia,
            sheetName: `Pedido ${numero}`,
            sheetFilter: [
              "Articulos",
              "Cantidad",
              "Surtido",
              "PendSurtir",
              "Precio",
              "Importe",
            ],
            sheetHeader: [
              "Articulos",
              "Cantidad",
              "Surtido",
              "PendSurtir",
              "Precio",
              "Importe",
            ],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editPedidos(event) {
    event.preventDefault();
    const URL_PEDIDOS_EDIT = `${URL_PEDIDOS}/${idEdit}`;

    axios
      .patch(
        URL_PEDIDOS_EDIT,
        {
          observaciones,
          editPedido: user.id,
          paqueteria,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Pedido",
            detalle: `${numero_pedido} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Fecha Inicio", field: "fechaInicio", sortable: true },
    { name: "Fecha Cancelacion", field: "fechaCancelacion", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Piezas", field: "total_cantidad", sortable: true },
    { name: "Piezas surtidas", field: "piezasEntregadas", sortable: true },
    { name: "Piezas Pendientes", field: "piezasPendientes", sortable: true },
    // { name: "Total", field: "total_general", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          // comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (status) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(status)
      );
    }
    if (autorizadoSurtido) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoSurtido.includes(autorizadoSurtido)
      );
    }
    if (autorizadoApartado) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoApartado.includes(autorizadoApartado)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      // sorting.field != "total_general" &&
      sorting.field != "piezasEntregadas" &&
      sorting.field != "piezasPendientes" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        // sorting.field == "total_general" ||
        sorting.field == "piezasEntregadas" ||
        sorting.field == "piezasPendientes" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        // sorting.field == "total_general" ||
        sorting.field == "piezasEntregadas" ||
        sorting.field == "piezasPendientes" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    status,
    selectedFechaInicio,
    selectedFechaFin,
    autorizadoApartado,
    autorizadoSurtido,
  ]);

  function EditArticulos(
    idOC,
    numero_pedido,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    total_cantidad,
    surtido,
    clientes,
    idClientes,
    fechaModificado,
    fecha,
    descuento
  ) {
    setComisionTDC(comisionTDC);
    setComisionFlete(comisionFlete);
    setSelectedImpuesto(impuestos);
    setSurtido(surtido);
    setIva(iva.toFixed(2));
    setTotalGeneralEdit(total_general);
    setTotalCantidadEdit(total_cantidad);
    setSubTotal(subTotal);
    setNombreClienteEdit(clientes);
    setIdClienteEdit(idClientes);
    setFechaModificado(fechaModificado);
    setFecha(fecha);
    setDescuento(descuento);

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
    setIdEdit(idOC);
    setNumeroPedido(numero_pedido);
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              is_active: a.is_active,
              articulos: a.articulos[0]._id,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
    setAbrirEditar(true);
  }

  const handleChangeInputExistentesPrecio = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.precio = event;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0 ||
      articulosPedido.filter((e) => e.articulos == event.target.value).length >
        0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      // const newInputFields = inputFields.map((i) => {
      //   if (id === i.id) {
      //     i.articulos = "";
      //   }
      //   return i;
      // });
      // setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == event.target.value) {
          let idArticulo = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          handleChangeInputExistentesArticulo(id, idArticulo, piezasPorCaja);
        }
      });
    }
  }

  const handleChangeInputExistentesArticulo = (
    id,
    idArticulo,
    piezasPorCaja
  ) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputCajasExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja) -
          parseFloat(i.surtido);
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputCantidadExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.cantidad = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir =
          parseFloat(event.target.value) - parseFloat(i.surtido);
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let KG = articulosPedido.concat(values);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG.toFixed(2));
  };

  const handleChangeInputPrecio = (id, value) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.precio = value;
        let totalParcial = i.cantidad * value;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0 ||
      articulosPedido.filter((e) => e.articulos == event.target.value).length >
        0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == event.target.value) {
          let idArticulo = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          handleChangeInputArticulo(id, idArticulo, piezasPorCaja, precio);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, idArticulo, piezasPorCaja, precio) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let DESC = 0
    let ivaTT = 0
    let activos = articulosPedido.filter((e) => e.is_active == "Si");
    let activos2 = activos.concat(inputFields);
    let cantidadActivos = activos2.map((c) => parseFloat(c.cantidad));
    let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TA);

    let totalActivos = activos2.map((c) => parseFloat(c.total));
    let TG = totalActivos.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG.toFixed(2));

    if (descuento != 0) {
      DESC = (TG * descuento) / 100;
    }else{
      DESC = 0
    }

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (TG - DESC)) / 100);
      ivaTT = (selectedImpuesto * (TG - DESC)) / 100
    } else {
      setIva(0);
    }


    setTotalGeneralEdit(TG + ivaTT + comisionFlete + comisionTDC - DESC);
    setFechaModificado(endDate);
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosPedido.length;
    const URL_PEDIDOS_EDIT = `${URL_PEDIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            total_general: parseFloat(total_general_edit).toFixed(2),
            total_cantidad: total_cantidad_edit,
            piezasPendientes: total_cantidad_edit,
            editArticulos: user.id,
            comisionTDC,
            comisionFlete,
            subTotal: parseFloat(subTotal).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos: selectedImpuesto,
            editado: "Si",
            fechaModificado,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: idEdit,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    cantidadProcesos: 0,
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total).toFixed(2),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    clientes: idClienteEdit,
                    fecha
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Pedido",
                        detalle: `${numero_pedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Pedido",
                    detalle: `${numero_pedido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${a.id}`,
                {
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total).toFixed(2),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (e * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(e) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(e)
    );
  }

  function autorizaCambio() {
    if (password == PASSWORD_CAMBIA_PRECIO) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function bajaArticulo(id) {
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${idEdit}`;
    const temp = articulosPedido.map((i) => {
      if (id === i.id) {
        i.is_active = "No";
      }
      return i;
    });

    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de baja!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Baja!",
    }).then((result) => {
      if (result.isConfirmed) {
        let activos = temp.filter((e) => e.is_active == "Si");
        let cantidadActivos = activos.map((c) => parseFloat(c.cantidad));
        let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TA);

        let totalActivos = activos.map((c) => parseFloat(c.total));
        let TG = totalActivos.reduce((t, total, index) => t + total, 0);
        setSubTotal(TG);

        let IT;
        if (selectedImpuesto != 0) {
          IT = (selectedImpuesto * TG) / 100;
        } else {
          IT = 0;
        }

        setIva(IT);

        let TGT =
          (selectedImpuesto * TG) / 100 +
          TG +
          parseFloat(comisionTDC) +
          parseFloat(comisionFlete);

        setTotalGeneralEdit(TGT.toFixed(2));

        axios
          .patch(
            `${URL_ARTICULOS_PEDIDO}/${id}`,
            {
              is_active: "No",
              autorizadoApartado: "No",
              autorizadoSurtido: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            //Patch Pedidos
            axios
              .patch(
                `${URL_PEDIDOS}/${idEdit}`,
                {
                  total_cantidad: TA,
                  total_general: TGT,
                  subTotal: TG,
                  iva: IT,
                  autorizadoApartado: "No",
                  autorizadoSurtido: "No",
                  autorizoApartado: user.id,
                  fechaModificado: hoy,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios
                  .patch(
                    URL_AUTORIZADO_HIJO,
                    {
                      autorizadoApartado: "No",
                      autorizadoSurtido: "No",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    const newAP = articulosPedido.map((i) => {
                      if (id === i.id) {
                        i.is_active = "No";
                      }
                      return i;
                    });
                    setArticulosPedido(newAP);

                    const newPed = comments.map((i) => {
                      if (idEdit === i._id) {
                        i.total_cantidad = TA;
                        i.total_general = TGT;
                        i.subTotal = TG;
                        i.iva = IT;
                        i.autorizadoApartado = "No";
                        i.autorizadoSurtido = "No";
                      }
                      return i;
                    });
                    setComments(newPed);

                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Se dio de Baja",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
            //Termina Patch Pedidos
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function altaArticulo(id) {
    const temp = articulosPedido.map((i) => {
      if (id === i.id) {
        i.is_active = "Si";
      }
      return i;
    });

    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de Alta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Alta!",
    }).then((result) => {
      if (result.isConfirmed) {
        let activos = temp.filter((e) => e.is_active == "Si");
        let cantidadActivos = activos.map((c) => parseFloat(c.cantidad));
        let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TA);

        let totalActivos = activos.map((c) => parseFloat(c.total));
        let TG = totalActivos.reduce((t, total, index) => t + total, 0);
        setSubTotal(TG);

        let IT;
        if (selectedImpuesto != 0) {
          IT = (selectedImpuesto * TG) / 100;
        } else {
          IT = 0;
        }

        setIva(IT);

        let TGT =
          (selectedImpuesto * TG) / 100 +
          TG +
          parseFloat(comisionTDC) +
          parseFloat(comisionFlete);

        setTotalGeneralEdit(TGT.toFixed(2));

        axios
          .patch(
            `${URL_ARTICULOS_PEDIDO}/${id}`,
            {
              is_active: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            //Patch Pedidos
            axios
              .patch(
                `${URL_PEDIDOS}/${idEdit}`,
                {
                  total_cantidad: TA,
                  total_general: TGT,
                  subTotal: TG,
                  iva: IT,
                  autorizadoApartado: "Si",
                  autorizadoSurtido: "Si",
                  autorizoApartado: user.id,
                  fechaModificado: hoy,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((response) => {
                const newAP = articulosPedido.map((i) => {
                  if (id === i.id) {
                    i.is_active = "Si";
                  }
                  return i;
                });
                setArticulosPedido(newAP);

                const newPed = comments.map((i) => {
                  if (idEdit === i._id) {
                    i.total_cantidad = TA;
                    i.total_general = TGT;
                    i.subTotal = TG;
                    i.iva = IT;
                    i.autorizadoApartado = "Si";
                    i.autorizadoSurtido = "Si";
                  }
                  return i;
                });
                setComments(newPed);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se dio de Alta",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
            //Termina Patch Pedidos
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function desautorizarSurtido(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoSurtido/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se quitara la autorizacion de Surtir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, quitar autorizacion!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoSurtido: "No",
              autorizadoApartado: "No",
              autorizoSurtido: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoSurtido: "No",
                  autorizadoApartado: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Desautorizar Surtido",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se quito la autorizacion",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoSurtido = "No";
                    i.autorizadoApartado = "No";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function autorizarSurtido(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoSurtido/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a autorizar Surtir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoSurtido: "Si",
              autorizadoApartado: "Si",
              autorizoSurtido: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoSurtido: "Si",
                  autorizadoApartado: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Autorizar Surtido",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se Autorizo",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoSurtido = "Si";
                    i.autorizadoApartado = "Si";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

        function desarrollo(){
      axios
        .get(
          `${URL_PEDIDOS}Desarrollo`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )      
        .then(() => {
          Swal.fire("Good job!", "Enviado con exito", "success");
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          {!AbrirEditar ? (
            <>
              <Row>
                <Col md={8}>
                  {user.pedidos_create ? (
                    <Button
                      size="sm"
                      type="submit"
                      className="btn btn-success"
                      href="/PedidosCreate"
                    >
                      Nuevo Pedido
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      type="submit"
                      className="btn btn-success"
                      disabled
                    >
                      Nuevo Pedido
                    </Button>
                  )}
                  <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      // onClick={desarrollo}
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pedidos</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Pedidos</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>

                <Col md={2}>
                  <Label>Autorizado</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={autorizadoApartado}
                    required
                    onChange={(e) => {
                      setAutorizadoApartado(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Autorizado Surtir</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={autorizadoSurtido}
                    required
                    onChange={(e) => {
                      setAutorizadoSurtido(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Status</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={status}
                    required
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    {/* <option value="">Selecciona</option> */}
                    <option value="Abierto">Abierto</option>
                    <option value="Cerrado">Cerrado</option>
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedCliente}
                      onChange={(e) => {
                        setSelectedCliente(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {clientes
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedColaborador}
                      onChange={(e) => {
                        setSelectedColaborador(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona un Vendedor</option>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        })}
                    </Input>
                  </td>
                </tr>
                <tbody>
                  {commentsData.map((a) => {
                    if (a.activo == "No") {
                      return (
                        <tr style={{ backgroundColor: "#ed8c8c" }}>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.fechaInicio}</td>
                          <td>{a.fechaCancelacion}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td></td>
                          <td align="right"></td>
                          <td>CANCELADO</td>
                          <td></td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.fechaInicio}</td>
                          <td>{a.fechaCancelacion}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.total_cantidad
                            )}
                          </td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.piezasEntregadas
                            )}
                          </td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.piezasPendientes
                            )}
                          </td>
                          {/* <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                              
                            }).format(a.total_general)}
                          </td> */}
                          <td>
                            {user.pedidos_create ? (
                              <div>
                                {a.status == "Abierto" ? (
                                  <>
                                    <Button
                                      size="sm"
                                      className="btn"
                                      color="danger"
                                      onClick={(e) =>
                                        PDFOC(
                                          a.fecha,
                                          a.colaboradores,
                                          a.idClientes,
                                          a.clientes,
                                          a.total_cantidad,
                                          a.total_general,
                                          a.observaciones,
                                          a.numero,
                                          a.impuestos,
                                          a.descuento,
                                          a.comisionFlete,
                                          a.comisionTDC,
                                          a._id,
                                          a.calle,
                                          a.numero_ext,
                                          a.numero_int,
                                          a.pais,
                                          a.estado,
                                          a.ciudad,
                                          a.delegacion,
                                          a.colonia,
                                          a.cp,
                                          a.RFC,
                                          a.paqueteria,
                                          a.codigoClientes,
                                          a.autorizadoApartado,
                                          a.autorizadoSurtido,
                                          a.telefono,
                                          a.editado,
                                          a.editPedido,
                                          a.autorizoApartado,
                                          a.autorizoSurtido,
                                          a.subTotal,
                                          a.iva
                                        )
                                      }
                                    >
                                      <i class="far fa-file-pdf"></i>
                                    </Button>
                                    <Button
                                      size="sm"
                                      className="btn"
                                      color="primary"
                                      onClick={(e) => excelOC(a._id, a.numero)}
                                    >
                                      <i class="far fa-file-excel"></i>
                                    </Button>
                                  </>
                                ) : undefined}

                                {a.surtido == "No" &&
                                user.pedidos_autorizar &&
                                a.status == "Abierto" ? (
                                  a.autorizadoSurtido == "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarSurtido(a._id)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) => autorizarSurtido(a._id)}
                                        size="sm"
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {a.surtido == "No" && user.pedidos_autorizar ? (
                                  <>
                                    <StatusMultiple
                                      idStatus={a._id}
                                      status={a.status}
                                      URL_STATUS={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                    <BajaMultiple
                                      idStatus={a._id}
                                      is_active={a.activo}
                                      URL_BAJA={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                  </>
                                ) : undefined}
                              </div>
                            ) : undefined}
                            {a.surtido == "No" ? (
                              a.status == "Abierto" && a.activo == "Si" ? (
                                <div>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      jalaInfo(
                                        a.fecha,
                                        a.idColaboradores,
                                        a.idClientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.paqueteria,
                                        a._id
                                      )
                                    }
                                  >
                                    <i class="far fa-edit"></i>
                                  </Button>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) =>
                                        EditArticulos(
                                          a._id,
                                          a.numero,
                                          a.comisionTDC,
                                          a.comisionFlete,
                                          a.impuestos,
                                          a.subTotal,
                                          a.iva,
                                          a.total_general,
                                          a.total_cantidad,
                                          a.surtido,
                                          a.clientes,
                                          a.idClientes,
                                          a.fechaModificado,
                                          a.fecha,
                                          a.descuento
                                        )
                                      }
                                    >
                                      <i class="fas fa-cart-plus"></i>
                                    </Button>
                                </div>
                              ) : undefined
                            ) : undefined}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          ) : (
            <div className="card container col-md-12">
              <h4>Editar Pedido {numero_pedido}</h4>
              <h4>Cliente: {nombreClienteEdit}</h4>
              <Form onSubmit={savePedido}>
                <Row>
                  <Col md={1}>
                    <Label>TDC</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionTDC}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionTDC(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Flete</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionFlete}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionFlete(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={selectedImpuesto}
                      onChange={(e) => {
                        calculaImpuesto(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Sub Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={subTotal}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>IVA</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={iva}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={total_general_edit}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Piezas</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Piezas"
                      value={total_cantidad_edit}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Label></Label>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={(e) => toggleCambiaPrecio()}
                    >
                      Cambiar Precio <i class="fas fa-dollar-sign"></i>
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulos</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Disponible</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Surtido</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Total</Label>
                  </Col>
                </Row>

                {articulosPedido.map((ao) => (
                  <div key={ao.id}>
                    <Row>
                      {ao.surtido == 0 ? (
                        <Col md={2}>
                          <Input
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            onChange={(event) => {
                              BuscaArticuloExistentes(ao.id, event);
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a._id}>
                                    {a.codigo} {a.nombre}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>
                      ) : (
                        <Col md={2}>
                          <Input
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a._id}>{a.nombre}</option>
                                );
                              })}
                          </Input>
                        </Col>
                      )}

                      <Col md={1}>
                        <Input type="select" disabled>
                          {inventarios.map((a) => {
                            if (ao.articulos == a.idArticulo) {
                              return (
                                <option value={a._id}>{a.disponible}</option>
                              );
                            }
                          })}
                        </Input>
                      </Col>

                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="cajas"
                          type="string"
                          value={ao.cajas}
                          onChange={(event) =>
                            handleChangeInputCajasExistentes(ao.id, event)
                          }
                        ></Input>
                      </Col>

                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="piezasPorCaja"
                          type="string"
                          value={ao.piezasPorCaja}
                          disabled
                        ></Input>
                      </Col>

                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={ao.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidadExistentes(ao.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="surtido"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="surtido"
                          value={ao.surtido}
                          disabled
                        />
                      </Col>

                      {user.cambiaPrecios && ao.surtido == 0 ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={ao.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputExistentesPrecio(ao.id, value)
                            }
                          />
                        </Col>
                      ) : cambiarPrecio ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={ao.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputExistentesPrecio(ao.id, value)
                            }
                          />
                        </Col>
                      ) : (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={ao.precio}
                            className={`form-control`}
                            disabled
                          />
                        </Col>
                      )}

                      <Col md={1}>
                        <CurrencyInput
                          intlConfig={{ locale: "en-US", currency: "USD" }}
                          prefix="$"
                          name="total"
                          defaultValue={0}
                          decimalsLimit={2}
                          value={ao.total}
                          className={`form-control`}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        {ao.is_active == "Si" ? (
                          <Button
                            color="primary"
                            id="Baja"
                            onClick={(e) => {
                              bajaArticulo(ao.id);
                            }}
                            size="sm"
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>
                        ) : (
                          <Button
                            color="danger"
                            onClick={(e) => {
                              altaArticulo(ao.id);
                            }}
                            size="sm"
                          >
                            <i class="far fa-times-circle"></i>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}

                {/* Agregar mas articulos */}

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>
                    <Row>
                      <Col md={2}>
                        <Input
                          // bsSize="sm"
                          name="articulos"
                          type="select"
                          value={inputField.articulos}
                          required
                          onChange={(event) => {
                            BuscaArticulo(inputField.id, event);
                          }}
                        >
                          <option value="0">Selecciona</option>
                          {articulos
                            .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.codigo} {a.nombre}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>

                      <Col md={1}>
                        <Input type="select" disabled>
                          {inventarios.map((a) => {
                            if (inputField.articulos == a.idArticulo) {
                              return (
                                <option value={a._id}>{a.disponible}</option>
                              );
                            }
                          })}
                        </Input>
                      </Col>

                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="cajas"
                          type="string"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCajas(inputField.id, event)
                          }
                        ></Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="piezasPorCaja"
                          type="string"
                          value={inputField.piezasPorCaja}
                          disabled
                        ></Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="surtido"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="0"
                          disabled
                        />
                      </Col>

                      {user.cambiaPrecios ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputPrecio(inputField.id, value)
                            }
                          />
                        </Col>
                      ) : cambiarPrecio ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputPrecio(inputField.id, value)
                            }
                          />
                        </Col>
                      ) : (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            disabled
                          />
                        </Col>
                      )}

                      <Col md={1}>
                        <CurrencyInput
                          intlConfig={{ locale: "en-US", currency: "USD" }}
                          prefix="$"
                          name="total"
                          defaultValue={0}
                          decimalsLimit={2}
                          value={inputField.total}
                          className={`form-control`}
                          disabled
                        />
                      </Col>
                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirEditar(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Pedido {numero_pedido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Paqueteria</Label>
              <Input
                type="text"
                placeholder="Paqueteria"
                value={paqueteria}
                required
                onChange={(e) => {
                  setPaqueteria(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPedidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalCambiaPrecio} toggle={toggleCambiaPrecio}>
        <ModalHeader toggle={toggleCambiaPrecio}>
          <h4>Autorizar Cambio de Precio</h4>
        </ModalHeader>
        <ModalBody>
          <Input
            bsSize="sm"
            type="password"
            placeholder="Password"
            value={password}
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <br />
          <Row>
            <Col>
              <Button
                type="submit"
                className="btn btn-success"
                onClick={(e) => autorizaCambio()}
              >
                Autorizar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalNoDisponible} toggle={toggleNoDisponible}>
        <ModalHeader toggle={toggleNoDisponible}>
          <h4>No hay Inventario Disponible</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Pedido</th>
              <th>Disponible</th>
            </tr>

            {allArticulosAutorizar.map((a) => {
              if (a.disponible < a.cantidadPedido) {
                return (
                  <tr style={{ backgroundColor: "#ed8c8c" }}>
                    <td>{a.articuloNombre}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.cantidadPedido)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.disponible)}
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr>
                    <td>{a.articuloNombre}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.cantidadPedido)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.disponible)}
                    </td>
                  </tr>
                );
              }
            })}
          </Table>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoPedidos;
